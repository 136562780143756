export const CERTIFICATIONS = {
  GOLD: 'gold',
  PLATINUM: 'platinum',
  PLATINUM_X2: 'platinum_x2',
  PLATINUM_X3: 'platinum_x3',
  PLATINUM_X4: 'platinum_x4',
  DIAMOND: 'diamond',
}

export const CERTIFICATIONS_CONFIG = {
  [CERTIFICATIONS.GOLD]: {
    icon: '📀',
    label: 'certifications.gold',
    id: 'gold',
  },
  [CERTIFICATIONS.PLATINUM]: {
    icon: '💿',
    label: 'certifications.platinum',
    id: 'platinum',
  },
  [CERTIFICATIONS.PLATINUM_X2]: {
    icon: '💿',
    count: 2,
    label: 'certifications.platinum_x2',
    id: 'platinum_x2',
  },
  [CERTIFICATIONS.PLATINUM_X3]: {
    icon: '💿',
    count: 3,
    label: 'certifications.platinum_x3',
    id: 'platinum_x3',
  },
  [CERTIFICATIONS.PLATINUM_X4]: {
    icon: '💿',
    count: 4,
    label: 'certifications.platinum_x4',
    id: 'platinum_x4',
  },
  [CERTIFICATIONS.DIAMOND]: {
    icon: '💎',
    label: 'certifications.diamond',
    id: 'diamond',
  },
}